import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import { httpStore } from "@/store/typed";
import { ContactForm } from "@/models";

export enum ContactFormTags {
  ContactFormCreate = "ContactFormCreate",
}

@Component
export default class ContactFormMixin extends Vue {
  async createContactForm(data: any): Promise<ContactForm> {
    const url = `${SD_BASE_API_URL}/contact_forms`;

    return httpStore.request({
      tag: ContactFormTags.ContactFormCreate,
      data: data,
      url: url,
      method: "POST",
    }) as Promise<ContactForm>;
  }
}


import { Component, Mixins } from "vue-property-decorator";
import ContactFormMixin from "@/mixins/http/ContactFormMixin";
import { zoneStore } from "@/store/typed";
import ThankYou from "@/components/Common/ThankYou.vue";
import VSvg from "@/components/Common/VSvg.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import { showToast, showErrorToast } from "@/utils/Toast";
import { SocialLink } from "@/models";
import { validateEmail } from "@/utils/Validations";

export interface FieldError {
  field: string;
  message: string;
}

export interface ContactFields {
  [propertyName: string]: string | boolean;
}

@Component({
  components: {
    VSvg,
    ThankYou,
  },
})
export default class ContactPage extends Mixins(ContactFormMixin) {
  private fields: ContactFields = {
    termsCheck: false,
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    administration: "",
  };
  private sent = false;
  private errors: FieldError[] = [];
  private requiredFields = [
    "name",
    "email",
    "termsCheck",
    "message",
    "administration",
  ];
  private get contactCards() {
    if (!zoneStore.zone) {
      return [];
    }

    return zoneStore.zone.contact_data;
  }

  get socialLinks(): SocialLink[] | undefined {
    return zoneStore.zone?.social_icons;
  }

  dynamicPageLink(page: string) {
    return { name: "dynamic_page", params: { page: page } };
  }

  svgIcon(id: string) {
    return `${require("@/assets/icons/sprite.svg")}#${id}`;
  }

  reset() {
    this.fields = {
      termsCheck: false,
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      administration: "",
    };
  }

  private async sendContactEmail() {
    this.checkErrors();

    if (this.errors.length) {
      return;
    }

    const payload = {
      name: this.fields.name as string,
      administration: this.fields.administration as string,
      email: this.fields.email as string,
      phone: this.fields.phoneNumber as string,
      message: this.fields.message as string,
    };

    const [data, errors] = await safeAsync(this.createContactForm(payload));

    if (errors) {
      showErrorToast("Si è verificato un errore");
    } else {
      this.reset();
      this.sent = true;
      showToast("Richiesta di contatto inviata!");
    }
  }

  private checkErrors() {
    this.errors = [];

    this.requiredFields.forEach((el: string) => {
      if (!this.fields[el]) {
        this.addError(el, "Campo obbligatorio");
      }
    });

    if (!validateEmail(this.fields.email as string)) {
      this.addError("email", "Email non valida");
    }
  }

  private addError(field: string, message: string) {
    if (this.hasError(field)) {
      return;
    }

    this.errors.push({ field: field, message: message });
  }

  private hasError(field: string) {
    const error = this.errors.find((el: FieldError) => el.field === field);

    return error ? true : false;
  }

  private getErrorMessage(field: string) {
    const error = this.errors.find((el: FieldError) => el.field === field);

    return error ? error.message : null;
  }
}
